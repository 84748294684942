<template src="./template.html"></template>

<script>
import qs from "qs"
var YTReady = false
export default {
  name: "HomeVideo",
  data() {
    return {
      player: null,
      done: false
    }
  },
  methods: {
    onPlayerReady(event) {
      this.player.mute();
      event.target.playVideo();
    },
    onPlayerStateChange(event) {
      event.target.mute();
      event.target.playVideo();
      // eslint-disable-next-line no-undef
      if (event.data == YT.PlayerState.PLAYING && !this.done) {
        event.target.mute();
        event.target.playVideo();
        this.done = true;
      }
    },
    stopVideo() {
      this.player.stopVideo();
    },
    async GetVideo() {
      let result = await this.SendPostData(process.env.VUE_APP_BASE_API + "web_edit/get_common_column.php", qs.stringify({ column_array: ["home_video"] }))
      if (result != "error") {
        let link = JSON.parse(result.data)[0].link
        let id = link.split("?v=")[1].split("&")[0]
        this.SetVideo(id)
      }
    },
    SetVideo(id) {
      let vm = this
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(tag, firstScript);
      window.onYouTubeIframeAPIReady = function () {
        YTReady = true
        // eslint-disable-next-line no-undef
        vm.player = new YT.Player('player', {
          videoId: id, // YouTube Video ID
          width: 277,               // Player width (in px)
          height: 600,              // Player height (in px)
          playerVars: {
            autoplay: 1,        // Auto-play the video on load
            controls: 0,        // Show pause/play buttons in player
            showinfo: 1,        // Hide the video title
            modestbranding: 1,  // Hide the Youtube Logo
            loop: 1,            // Run the video in a loop
            fs: 0,              // Hide the full screen button
            cc_load_policy: 0, // Hide closed captions
            iv_load_policy: 3,  // Hide the Video Annotations
            autohide: 1,         // Hide video controls when playing
            playsinline: 1, //forbid fullscreen on ios
          },
          events: {
            onReady: (e) => {
              e.target.mute();
              e.target.playVideo()
            },
            onStateChange: (e) => { vm.onPlayerStateChange(e) }
          }
        });
      }
      // eslint-disable-next-line no-undef
      if (YTReady) {
        // eslint-disable-next-line no-undef
        vm.player = new YT.Player('player', {
          videoId: '40VVHQrWOxo', // YouTube Video ID
          width: 277,               // Player width (in px)
          height: 600,              // Player height (in px)
          playerVars: {
            autoplay: 1,        // Auto-play the video on load
            controls: 0,        // Show pause/play buttons in player
            showinfo: 1,        // Hide the video title
            modestbranding: 1,  // Hide the Youtube Logo
            loop: 1,            // Run the video in a loop
            fs: 0,              // Hide the full screen button
            cc_load_policy: 0, // Hide closed captions
            iv_load_policy: 3,  // Hide the Video Annotations
            autohide: 1,         // Hide video controls when playing
            playsinline: 1, //forbid fullscreen on ios
          },
          events: {
            onReady: (e) => {
              e.target.mute();
              e.target.playVideo()
            },
            onStateChange: (e) => { vm.onPlayerStateChange(e) }
          }
        });
      }
    }
  },
  mounted() {
    this.GetVideo()
  }
}
</script>