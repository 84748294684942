<template src="./tempalte.html"></template>



<script>
export default {
  name: "MainNav",
  data() {
    return {
      MenuActive: false,
      screenWidth: document.body.clientWidth,
      screenScroll: document.body.scrollTop,
      shopcart_amount: 0,
      message_show: null,
      message_state: false,
      nav_list: [
        {
          title: "關於我們",
          sub_title: "ABOUT",
          link: "/about",
          pages: ["關於我們"],
        },
        {
          title: "產品列表",
          sub_title: "PRODUCTS",
          link: "/product/list/all",
          pages: ["產品列表", "商品介紹"],
        },
        {
          title: "影音相簿",
          sub_title: "VIDEO GALLERY",
          link: "/video/list",
          pages: ["影音相簿", "影音內容"],
        },
        {
          title: "最新消息",
          sub_title: "NEWS",
          link: "/news/list",
          pages: ["最新消息", "新聞內容"],
        },
        {
          title: "客服中心",
          sub_title: "SUPPORT",
          link: "/support",
          pages: ["客服中心", "聯絡我們"],
        },
      ],
    };
  },
  mounted() {
    const that = this;

    that.screenScroll =
      document.documentElement.scrollTop + document.body.scrollTop;

    window.onscroll = () => {
      return (() => {
        that.screenScroll =
          document.documentElement.scrollTop + document.body.scrollTop;
      })();
    };
  },
  methods: {
    SetShopCartDrawer() {
      this.$store.commit("SetShopCartDrawer", true);
    },
  },

  watch: {
    screenScroll(val) {
      if (!this.scrolltimer) {
        this.screenScroll = val;
        this.scrolltimer = true;
        let that = this;
        setTimeout(function () {
          that.scrolltimer = false;
        }, 400);
      }
    },
    "$store.state.shopcart_message"() {
      if (this.$store.state.shopcart_message != 0) {
        this.message_show == null ? "" : clearTimeout(this.message_show);
        this.message_state = false;
        setTimeout(() => {
          this.message_state = true;
        }, 100);
        this.message_show = setTimeout(() => {
          this.$store.commit("SetShopCartMessage", 0);
        }, 4000);
      } else {
        this.message_show = null;
        this.message_state = false;
      }
    },
  },
  computed: {
    bg_show() {
      return this.$route.name != "首頁" || this.screenScroll > 200
        ? true
        : false;
    },
    shopcart() {
      let amount = 0;
      this.$store.state.shopcart.forEach((item) => {
        amount += parseInt(item.product_amount);
      });
      return amount;
    },
    msg_show() {
      return this.$store.state.shopcart_message;
    },
  },
};
</script>
