<template>
  <div id="MainLoading" :class="{ active: $store.state.loading }">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1408.2 339.71">
      <path
        d="M2277.32-127h-15.38c-3.25,0-5.6-1.09-5.6-2.59s2.35-2.59,5.6-2.59h27.25v-7.5H2261.1c-9.25,0-16.22,4.34-16.22,10.09,0,5.94,6.67,10.09,16.22,10.09h15.38c3.24,0,5.59,1.09,5.59,2.59s-2.35,2.59-5.59,2.59h-30.76v7.5h31.6c9.09,0,16.21-4.44,16.21-10.09C2293.53-122.81,2286.86-127,2277.32-127Z"
        transform="translate(-1755.43 429.16)"
        class="cl-1"
      />
      <rect x="632.53" y="289.53" width="11.59" height="32.85" class="cl-1" />
      <polygon
        points="728.66 308.08 728.82 322.38 740.26 322.38 740.26 309.7 776.19 309.7 776.19 302.2 740.26 302.2 740.26 297.02 776.75 297.02 776.75 289.52 728.66 289.52 728.66 308.08"
        class="cl-1"
      />
      <path
        d="M2663.53-120.53c0,3.69-5.41,6.58-12.31,6.58s-12.45-2.89-12.45-6.58v-19.11h-11.59v18.7c0,8.58,9.71,14.56,23.62,14.56,14.1,0,24.33-6.12,24.33-14.56v-18.7h-11.6Z"
        transform="translate(-1755.43 429.16)"
        class="cl-1"
      />
      <polygon
        points="1014.82 297.02 1033.56 297.02 1033.56 322.38 1045.01 322.38 1045.01 297.02 1063.75 297.02 1063.75 289.52 1014.82 289.52 1014.82 297.02"
        class="cl-1"
      />
      <path
        d="M2941.43-140c-15.49,0-28.1,7.55-28.1,16.83,0,9.91,11.56,16.83,28.1,16.83,15.42,0,28-7.55,28-16.83C2969.39-133.12,2957.89-140,2941.43-140Zm0,26.09c-9.17,0-16.36-4.07-16.36-9.26s7.19-9.26,16.36-9.26,16.23,4.07,16.23,9.26S2950.53-113.95,2941.43-113.95Z"
        transform="translate(-1755.43 429.16)"
        class="cl-1"
      />
      <polygon
        points="1344.6 289.52 1344.6 310.71 1310.12 289.52 1300.98 289.52 1300.98 322.38 1312.57 322.38 1312.57 301.19 1347.05 322.38 1356.19 322.38 1356.19 289.52 1344.6 289.52"
        class="cl-1"
      />
      <path
        d="M3024-368.83h24.79l-7.71-11.44c7,0,13.6,0,20.15,0,3.75,0,8.1-1,11.09.53s4.47,5.64,6.41,8.76c1,1.63,2.21,1.94,4,2.17,6.23.8,11-1.25,15.36-5.78,4.17-4.35,9-8,13.6-12a5.63,5.63,0,0,0,.35-.52H3029.8v-22.38h118.31a11.86,11.86,0,0,1,.23,1.66c0,7.8.06,15.61,0,23.41a4.16,4.16,0,0,1-1.38,2.76c-5.06,4.15-10.22,8.17-16,12.74h27.2v3.13c0,29.2,0,58.4,0,87.6,0,8.65-2.16,16.27-9.06,22a17.37,17.37,0,0,1-10.79,4.26c-7.34.16-14.68,0-22.18,0v-20.38c2.22,0,4.46,0,6.7,0,4.43,0,6.1-1.48,6.77-5.92.17-1.12.3-2.25.47-3.6h-25.32v28.05h-28.2v-28.09h-24.47v29.73h-28Zm80.78,54.05v12.07h25.05v-12.07Zm-52.74,12h24.24v-11.92h-24.24Zm52.81-44.43v11.44h25v-11.44Zm-28.54.08h-24.19v11.33h24.19Z"
        transform="translate(-1755.43 429.16)"
        class="cl-2"
      />
      <path
        d="M2324.9-410.61c-.74,3.74-1.46,7.38-2.23,11.3h61c-2.49,17.48-4.93,34.66-7.39,51.91h-26.39c1.72-9.58,3.42-19,5.15-28.6-1-.05-1.76-.13-2.52-.14-10.8,0-21.6,0-32.39,0-1.79,0-2.5.38-2.93,2.32-1.5,6.81-2.81,13.74-5.18,20.27-2.53,7-8.42,10.3-15.81,10.58-4.45.17-8.91,0-13.63,0v7h-8.51v114.3h-27.26V-335.72h-19v94.23c0,5.46-.78,10.68-5,14.75a16.11,16.11,0,0,1-10.51,4.89c-6.44.29-12.9.08-19.49.08v-21.66c1.29,0,2.56,0,3.83,0,3.12-.09,3.82-.81,3.83-4V-400.83h45.4c8.26,0,9.33-.87,10.75-8.92h27c-.18,6.77.76,13.52-1.16,20.12-1.88,6.45-8.68,11.12-17.29,11.39-11.31.34-22.64.3-33.95.43H2228v16.9h52v-5.73c1.07-.06,1.84-.13,2.61-.13,4.45-.06,5.68-.56,6.87-4.9,1.73-6.31,2.93-12.77,4.24-19.19s2.55-13.05,3.85-19.75Z"
        transform="translate(-1755.43 429.16)"
        class="cl-2"
      />
      <path
        d="M2585.68-221.5v-72.23h186.05v3c0,17.36-.12,34.72.05,52.08.11,11.73-4.76,15.25-15.49,16.68a77.82,77.82,0,0,1-10.25.43q-78.45,0-156.89,0Zm106.66-45.45h51v-8.6h-51Zm0,27.17h12.06c10.62,0,21.23.08,31.85,0,6.76-.07,8.65-2.63,6.9-8.81h-50.81Zm-78.39-35.79v8.62h49.34v-8.62Zm0,26.89v8.75h49.32v-8.75Z"
        transform="translate(-1755.43 429.16)"
        class="cl-2"
      />
      <path
        d="M3163.64-246.49v25h-3.46c-42.82,0-85.65-.1-128.47.09a38.34,38.34,0,0,1-21.38-6,3.91,3.91,0,0,0-4.87,0c-9.08,5.76-19.23,7.66-30.11,7.92v-2.88c0-6.76.05-13.51,0-20.26,0-1.76.28-2.86,2.29-3a9.25,9.25,0,0,0,2.53-.69c5.25-1.88,6.35-3.75,6.33-8.85-.11-23.15-.05-46.3-.05-69.45v-3.15h-10V-355h37.17v3.1c0,27.89-.12,55.78.06,83.66.07,10.25,8.52,21,20.68,21.59,2,.09,4,.12,6,.12h123.23Z"
        transform="translate(-1755.43 429.16)"
        class="cl-2"
      />
      <path
        d="M2344.59-355.57c-2.12,8.33-4.14,16.27-6.26,24.58,5.57,0,10.8.1,16-.05,2.12-.06,2.77.64,3.09,2.73,3,19.55,6.19,39.07,9.29,58.61q1.74,11,3.38,22c.58,4,.83,4.21,4.78,4.22,2.26,0,4.53,0,7,0v24.29c-8.36-.28-16.71,0-24.92-1-7.85-.92-12.33-6.3-13.53-14-1.94-12.54-3.65-25.12-5.44-37.68-1.65-11.62-3.26-23.24-4.9-34.85-.08-.51-.25-1-.59-2.3-1.83,7.05-3.46,13.36-5.1,19.67-4.93,19-9.8,37.93-14.84,56.86a25.78,25.78,0,0,1-3.42,7,8.29,8.29,0,0,1-7,4c-8.14.06-16.28,0-24.57,0V-245c2.18,0,4.27-.15,6.34,0,2.85.25,4.24-1.23,4.88-3.7q2.25-8.64,4.42-17.29,10.94-43.41,21.78-86.84c.57-2.31,1.53-2.8,3.7-2.77C2327.2-355.5,2335.71-355.57,2344.59-355.57Z"
        transform="translate(-1755.43 429.16)"
        class="cl-2"
      />
      <path
        d="M2592.81-298.87V-344.2H2764.4a5.75,5.75,0,0,1,.22,1.08q0,15.39,0,30.78c0,7.78-5.28,11.41-11.19,12.45a63,63,0,0,1-11,1q-73.32.09-146.63,0Zm144.12-27.06H2618.45v8.82h111.06c6.95,0,8.31-1.53,7.62-8.48C2737.12-325.67,2737.05-325.74,2736.93-325.93Z"
        transform="translate(-1755.43 429.16)"
        class="cl-2"
      />
      <path
        d="M2743.54-370.6v-8.62H2611.31v8.57h-26.25v-28.51h76.62v-9.75h33.76v9.63H2770v28.68Z"
        transform="translate(-1755.43 429.16)"
        class="cl-2"
      />
      <path
        d="M2769.1-367.44v18.7H2588.27v-18.7Z"
        transform="translate(-1755.43 429.16)"
        class="cl-2"
      />
      <path
        d="M2983.32-408.12h30.1v37.54h-30.1Z"
        transform="translate(-1755.43 429.16)"
        class="cl-2"
      />
      <path
        d="M1755.43-224.89v49.7l72.07,41.73,17.44,9.92,1.65,1.14,4.23,2.45V-349.08l95-54.85-43.91-25.23-146.48,84.82v50.42l4.26-2.48,21-12.11,1.59-1,7.52-4.33v0l17.23-9.94v128.24l-32.83-18.71v0l-3.5-2Z"
        transform="translate(-1755.43 429.16)"
        class="cl-1"
      />
      <polygon
        points="206.45 157.6 111.42 212.66 111.42 212.72 95.44 221.97 139.36 247.26 190.03 218.01 190.03 217.89 206.45 208.42 206.45 157.6"
        class="cl-2"
      />
      <path
        d="M1985.28-381l-21.23,12.44-26.87,15.51,0,0-42.36,24.46v50.55l26-15h0l49.89-28.78,33.06-19.53h0l1.55-.91-.2,40.43v101.74L1962-175.25l0,0-62.31,36-.07,49.84,148.95-86.26V-344.43Z"
        transform="translate(-1755.43 429.16)"
        class="cl-2"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "Loading",
  components: {},
};
</script>