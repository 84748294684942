import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueMeta from 'vue-meta'
import CommonMethods from './common/common_methods'
import AOS from 'aos';
import 'aos/dist/aos.css';
import VueGtm from 'vue-gtm'
import GA4 from './common/gtm_methods'
var VueCookie = require('vue-cookie');
import qs from "qs"
import prerender_event from "./common/prerender_event"
Vue.mixin(prerender_event)
// Tell Vue to use the plugin


Vue.use(VueCookie);
Vue.mixin(GA4)
Vue.use(AOS)
Vue.mixin(CommonMethods)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: false
})

Vue.config.productionTip = false
Vue.axios.post(process.env.VUE_APP_BASE_API + 'web_edit/get_common_column.php', qs.stringify({
  column_array: ["google_tag_manager"]
})).then(
  response => {
    if (response.data.status == 'success') {
      Vue.use(VueGtm, {
        id: JSON.parse(response.data.data)[0].title,
        enabled: true,
        debug: true,
        loadScript: true,
        vueRouter: router,
        trackOnNextTick: false
      })
    }
  }
)


new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')