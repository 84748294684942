<template src="./template.html"></template>

<script>
import qs from "qs"
export default {
  name: "HomeSocial",
  data() {
    return {
      social_data: []
    }
  },
  methods: {
    async GetSocialData() {
      let result = await this.SendPostData(process.env.VUE_APP_BASE_API + "web_edit/get_common_column.php", qs.stringify({ column_array: ["home_social_1", "home_social_2"] }))
      if (result != "error") {
        this.social_data = JSON.parse(result.data)
      }
    },
  },
  created() {
    this.GetSocialData()
  }
}
</script>