export default {
    methods: {
        GA_Purchase(order_data) {
            let data = {
                event: 'purchase',
                category: '完成訂單', // 類別 字元(必填)
                action: 'click', // 動作 字元(必填)
                label: "完成訂單", // 標籤 字元(選填)
                order_no: order_data.order_id,
                items: [],
                pay_way: this.PaymentConvert(order_data.payment),
                shipping: order_data.shipping_fee,
                value: parseInt(order_data.total_price)
            }

            order_data.products.forEach(product => {
                data.items.push({
                    item_name: product.name,
                    quantity: product.amount,
                    promotion_name: "一般消費",
                    item_category: product.category,
                    item_brand: "銳仕技研",
                    price: parseInt(product.price),
                    value: parseInt(product.price),
                    item_variant: product.options,
                    currency: "TWD",
                })
            });
            this.$gtm.trackEvent(data);
        },
        GA_AddToCart(name, option, price, amount) {
            let data = {
                event: 'add_to_cart',
                category: '加入購物車', // 類別 字元(必填)
                action: 'click', // 動作 字元(必填)
                label: "加入購物車", // 標籤 字元(選填)
                items: [{
                    item_name: name,
                    quantity: amount,
                    promotion_name: "一般消費",
                    item_brand: "銳仕技研",
                    price: parseInt(price),
                    value: parseInt(price),
                    item_variant: option,
                    currency: "TWD",
                }],
                currency: "TWD",
            }
            this.$gtm.trackEvent(data);
        },
        // GA_RemoveFromCart() {
        //     let product = this.$store.state.shopcart[product_index]
        //     let data = {
        //         event: 'remove_from_cart',
        //         category: '商品從購物車移除', // 類別 字元(必填)
        //         action: 'click', // 動作 字元(必填)
        //         label: "商品從購物車移除", // 標籤 字元(選填)
        //         items: [{
        //             name: product.name,
        //             quantity: product.amount,
        //             promotion_name: "一般消費",
        //             brand: "銳士技研銳仕技研",
        //             price: parseInt(product.price),
        //             variant: product.option_name,
        //             currency: "TWD",
        //         }],
        //         currency: "TWD",
        //     }
        //     this.$gtm.trackEvent(data);
        // },
        GA_BeginCheckout(products) {
            let data = {
                event: 'begin_checkout',
                category: '開始結帳', // 類別 字元(必填)
                action: 'click', // 動作 字元(必填)
                label: "開始結帳", // 標籤 字元(選填)
                items: []
            }

            products.forEach(product => {
                data.items.push({
                    item_name: product.name,
                    quantity: product.amount,
                    promotion_name: "一般消費",
                    item_brand: "銳仕技研",
                    price: parseInt(product.price),
                    value: parseInt(product.price),
                    item_variant: product.option,
                    currency: "TWD",
                })
            });
            this.$gtm.trackEvent(data);
        },
        GA_SelectItem(product) {
            let data = {
                event: 'select_item',
                category: '選擇商品', // 類別 字元(必填)
                action: 'click', // 動作 字元(必填)
                label: "選擇商品", // 標籤 字元(選填)
                items: [{
                    name: product.name,
                    promotion_name: "一般消費",
                    brand: "銳仕技研",
                    price: "",
                    variant: "",
                    currency: "TWD",
                }],
                currency: "TWD",
            }
            this.$gtm.trackEvent(data);
        },
        PaymentConvert(val) {
            if (val == 'Credit') {
                return '信用卡付款'
            } else if (val == 'COD') {
                return '貨到付款'
            } else if (val == 'CVS') {
                return '超商代碼繳費'
            } else if (val == 'ATM') {
                return 'ATM匯款'
            } else if (val == 'WebATM') {
                return 'WebATM匯款'
            } else if (val == 'BARCODE') {
                return '超商條碼繳費'
            } else if (val == 'C2C') {
                return '超商取貨付款'
            }
        },
    }
}