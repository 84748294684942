<template>
  <div class="home">
    <HomeTopCarousel />
    <HomeProductPromote />
    <HomeNews />
    <HomeVideo v-if="first_touch" />
    <HomeSocial />
  </div>
</template>

<script>
import HomeNews from '../components/Home/HomeNews/index';
import HomeTopCarousel from '../components/Home/HomeTopCarousel/index';
import HomeProductPromote from '../components/Home/HomeProductPromote/index';
import HomeVideo from '../components/Home/HomeVideo/index';
import HomeSocial from '../components/Home/HomeSocial/index';
export default {
  name: 'Home',
  components: {
    HomeNews,
    HomeTopCarousel,
    HomeProductPromote,
    HomeSocial,
    HomeVideo,
  },
  data() {
    return {
      first_touch: false,
    };
  },
  mounted() {
    document.addEventListener('touchstart', () => {
      //使用者觸碰過網頁後才能啟用自動播放
      this.first_touch = true;
    });
    document.addEventListener('scroll', () => {
      //使用者觸碰過網頁後才能啟用自動播放
      this.first_touch = true;
    });
    window.prerenderReady = true;
  },
  watch: {
    $route() {
      this.first_touch = false;
    },
  },
  metaInfo() {
    return {
      title: '首頁｜欣富通｜SIFUTON',
      meta: [
        {
          property: 'og:description',
          content:
            '欣富通成立於1993年，專注於生產機械停車和油壓電梯高品質的昇降系統，用專業知識及技術創新來服務國內產業界，一直為客戶提供安全可靠和耐用持久的產品，隨著時間的累積，我們對客戶的需求持續關注使我們在各種應用積累了經驗，增加了我們的產品組合，並成為昇降系統應用的領先製造商。',
          vmid: 'og:description',
        },
        {
          property: 'twitter:description',
          content:
            '欣富通成立於1993年，專注於生產機械停車和油壓電梯高品質的昇降系統，用專業知識及技術創新來服務國內產業界，一直為客戶提供安全可靠和耐用持久的產品，隨著時間的累積，我們對客戶的需求持續關注使我們在各種應用積累了經驗，增加了我們的產品組合，並成為昇降系統應用的領先製造商。',
          vmid: 'twitter:description',
        },
        {
          property: 'description',
          content:
            '欣富通成立於1993年，專注於生產機械停車和油壓電梯高品質的昇降系統，用專業知識及技術創新來服務國內產業界，一直為客戶提供安全可靠和耐用持久的產品，隨著時間的累積，我們對客戶的需求持續關注使我們在各種應用積累了經驗，增加了我們的產品組合，並成為昇降系統應用的領先製造商。',
          vmid: 'description',
        },
        {
          property: 'og:image',
          content: 'https://www.sifuton.com.tw/img/share/index.jpg',
          vmid: 'og:image',
        },
        {
          property: 'apple-mobile-web-app-title',
          content: '首頁｜欣富通｜SIFUTON',
          vmid: 'apple-mobile-web-app-title',
        },
        {
          property: 'application-name',
          content: '首頁｜欣富通｜SIFUTON',
          vmid: 'application-name',
        },
        {
          property: 'og:site_name',
          content: '首頁｜欣富通｜SIFUTON',
          vmid: 'og:site_name',
        },
        {
          property: 'og:type',
          content: 'website',
          vmid: 'og:type',
        },
        {
          property: 'og:locale',
          content: 'zh_tw',
          vmid: 'og:locale',
        },
      ],
    };
  },
};
</script>
