<template src="./template.html"></template>

<script>
import qs from "qs"
export default {
  name: "MainFooter",
  data() {
    return {
      page_data: []
    }
  },
  methods: {
    async GetContactData() {
      let result = await this.SendPostData(process.env.VUE_APP_BASE_API + "web_edit/get_common_column.php", qs.stringify({ column_array: ["company_phone", "company_email", "company_time"] }))
      if (result != "error") {
        this.page_data = JSON.parse(result.data)
      }
    },
    GetCompanyData(key) {
      let result = this.page_data.filter(item => item.column_name == key)
      return result.length > 0 ? result[0] : { title: "", content: "", image: "", link: "" }
    }
  },
  created() {
    this.GetContactData()
  }
}
</script>