import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    dialog: {
      title: "",
      content: "",
      status: false
    }
  },
  mutations: {
    SetPageLoading(state, action) {
      state.loading = action
    },
    SetDialog(state, {
      title,
      content,
      status
    }) {
      state.dialog.title = title
      state.dialog.content = content
      state.dialog.status = status
    }
  },
  actions: {},
  modules: {}
})