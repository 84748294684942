import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

let router = new VueRouter({
  mode: 'history',
  base: '/',
  routes: [{
    path: '/',
    name: '首頁',
    component: Home
  },
  {
    path: '/about',
    name: '關於我們',
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/support',
    name: '客服中心',
    component: () => import( /* webpackChunkName: "support" */ '../views/Support.vue')
  },
  {
    path: '/contact',
    name: '聯絡我們',
    component: () => import( /* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/product/list/:type',
    name: '產品列表',
    component: () => import( /* webpackChunkName: "product_list" */ '../views/ProductList.vue')
  },
  {
    path: '/product/:id',
    name: '商品介紹',
    component: () => import( /* webpackChunkName: "product_detail" */ '../views/ProductDetail.vue')
  },
  {
    path: '/news/list',
    name: '最新消息',
    component: () => import( /* webpackChunkName: "news_list" */ '../views/NewsList.vue')
  },
  {
    path: '/video/list',
    name: '影音相簿',
    component: () => import( /* webpackChunkName: "video_gallery" */ '../views/VideoGallery.vue')
  },
  {
    path: '/video/:id',
    name: '影音內容',
    component: () => import( /* webpackChunkName: "video_page" */ '../views/VideoPage.vue')
  },
  {
    path: '/news/:id',
    name: '新聞內容',
    component: () => import( /* webpackChunkName: "news_page" */ '../views/NewsPage.vue')
  },
  {
    path: '/index.html',
    redirect: '/',
  },
  {
    path: '/home',
    redirect: '/',
  },
  {
    path: '/index.php',
    name: 'BotHome',
    component: Home
  },
  {
    path: '/error_page',
    name: '404Page',
    component: () => import( /* webpackChunkName: "404Page" */ '../views/404Page.vue')
  },
  {
    path: "*",
    redirect: "/error_page"
  }
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})
export default router;