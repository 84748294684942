<template src="./template.html"></template>

<script>
export default {
  name: "HomeNews",
  data() {
    return {
      news_data: [
      ]
    }
  },
  methods: {
    async GetNewsoData() {
      let result = await this.SendGetData(process.env.VUE_APP_BASE_API + 'news/get_news_list.php')
      if (result != "error") {
        let data = JSON.parse(result.data)
        this.news_data = data
      }
    }
  },
  created() {
    this.GetNewsoData()
  },
  filters: {
    wordbreak(val) {
      return val.length >= 25 ? val.slice(0, 20) + "..." : val
    }
  }
}
</script>