<template src="./template.html"></template>

<script>
import qs from "qs"
export default {
  name: "HomeProductPromote",
  data() {
    return {
      product_data: [
        // {
        //   id: "1",
        //   title: "RC-01",
        //   content: "高性能、高支撐、超舒適的頂級內管，透過特殊設計內部機構可體現三者兼具的頂級效能。<br>配合日本 Nok 油封組合在一起，即是無與倫比的極致體驗。特殊油路設計、預載24段可調、阻尼27段可調。",
        //   image: "/img/index_section/product_01.jpg"
        // },
        // {
        //   id: "2",
        //   title: "CP-1",
        //   content: "高性能、高支撐、超舒適的頂級內管，透過特殊設計內部機構可體現三者兼具的頂級效能。<br>配合日本 Nok 油封組合在一起，即是無與倫比的極致體驗。特殊油路設計、預載24段可調、阻尼27段可調。",
        //   image: "/img/index_section/product_02.jpg"
        // }
      ],
      hover_item: -1
    }
  },
  methods: {
    ChangeHoverItem(item) {
      this.hover_item = item
    },
    async GetProductPromote() {
      let result = await this.SendPostData(process.env.VUE_APP_BASE_API + "web_edit/get_common_column.php", qs.stringify({ column_array: ["home_product_1", "home_product_2"] }))
      if (result != "error") {
        this.product_data = JSON.parse(result.data)
      }
    }
  },
  created() {
    this.GetProductPromote()
  }
}
</script>