<template>
  <div id="app">
    <MainDialog />
    <Lodaing />
    <MainNav />
    <router-view class="page_view" />
    <MainFooter />
  </div>
</template>

<script>
import "@/assets/css/app.scss";
import MainNav from "./components/MainNav/index";
import MainFooter from "./components/MainFooter/index";
import MainDialog from "./components/MainDialog/index";
import Lodaing from "./components/Loading";
import AOS from "aos";
export default {
  name: "app",
  components: {
    MainNav,
    MainFooter,
    MainDialog,
    Lodaing,
  },
  data() {
    return {
      FirstAnimation: false,
    };
  },
  created() {
    AOS.init();
    window.addEventListener("load", () => {
      AOS.init();
    });
  },
  watch: {
    $route() {
      AOS.refresh();
    },
  },
};
</script>
