<template src="./template.html"></template>

<script>
export default {
  name: "HomeTopCarousel",
  data() {
    return {
      images_data: [
        {
          id: 1,
          image: "/img/index_carousel/1.jpg",
        },
        {
          id: 2,
          image: "/img/index_carousel/2.jpg",
        },
        {
          id: 3,
          image: "/img/index_carousel/3.jpg",
        },
        {
          id: 4,
          image: "/img/index_carousel/4.jpg",
        },
      ],
      active_image: 0,
      out_image: 1,
      auto_method: null,
    };
  },
  created() {
    this.GetCarouselData();
  },
  methods: {
    ChangeImage(val) {
      if (val != this.active_image) {
        this.out_image = this.active_image;
        this.active_image = val;
        let el = this.$refs.image_row;
        el.style.transform = `translateX(-${this.active_image * 100}%)`;
        clearInterval(this.auto_method);
        this.auto_method = setInterval(this.ImageInterval, 8000);
      }
    },
    ImageInterval() {
      if (this.$route.name == "首頁") {
        this.active_image == this.images_data.length - 1
          ? ((this.active_image = 0),
            (this.out_image = this.images_data.length - 1))
          : ((this.active_image += 1),
            (this.out_image = this.active_image - 1));
        let el = this.$refs.image_row;
        el.style.transform = `translateX(-${this.active_image * 100}%)`;
      }
    },
    async GetCarouselData() {
      let result = await this.SendGetData(
        process.env.VUE_APP_BASE_API + "web_edit/get_home_carousel.php"
      );
      if (result != "error") {
        this.images_data = JSON.parse(result.data);
        this.images_data.sort((a, b) => {
          return a.position - b.position;
        });
        this.auto_method = setInterval(this.ImageInterval, 8000);
      }
    },
  },
};
</script>